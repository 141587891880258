*{
  padding: 0;
  margin: 0;
}


.is-bg-color1{
  background-color: #30475e;
}

.is-color1{
  color: #30475e;
}

.is-bg-color2{
  background-color: #27496d;
}

.is-color2{
  color: #27496d;
}

.is-bg-color3{
  background-color: #0c7b93;
}

.is-color3{
  color: #0c7b93;
}

.is-bg-color4{
  background-color: #e7b2a5;
}

.is-color4{
  color: #00a8cc;
}

.is-color4:hover{
  color: white;
}

#white-color{
  color: white;
}

.is-w{
  color: white
}

.mx-16{
  margin: 0px 4.5rem;
}

.my-16{
  margin: 4.2rem 0px;
}

.px-4{
  padding: 0px 2.6rem;
}