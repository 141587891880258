.get-started-btn{
    margin: 20px 0px;
}

.cl-green{
    background-color: green;
}

/* .main-contain{
    margin: 20px 10.5rem;
} */



.space-me{
    margin-top: 10px;
}